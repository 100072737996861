import React, { useEffect, useRef, useState } from "react";
import { Box, List, ListItem, ListItemText, Avatar, Typography, CircularProgress, Paper, TextField, Button, IconButton } from "@mui/material";
import { useAuth } from "../../auth";
import { useLocation, useNavigate } from "react-router-dom";
import isUserLoggedIn from "./helpers/redirect";
// import { NoDataSvg } from "./helpers/Svg";
import { ArrowBack } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


function ExpertChatDB() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [identifier, setIdentifier] = useState(0);
  const [clientUserID, setClientUserID] = useState(0)
  const [newClient, setNewClient] = useState(null);

  const chatRef = useRef();

  const searchParams = new URLSearchParams(location.search);
    
  const clientName = searchParams.get("clientName");
  const clientID = searchParams.get("clientID");


 
  // Check if the user is logged in
  useEffect(() => {
    const isLoggedIn = isUserLoggedIn();
    if (!isLoggedIn) {
      window.location.href = `https://www${process.env.REACT_APP_CLIENT_DOMAIN}/`
    }
  }, [location, navigate]);

  // Fetch the list of clients

  const fetchClients = async () => {
    try {
      const response = await fetch(`https://integrations.uwc.world/api/offline_chat/get_all_chats_for_expert/${currentUser?.UserId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 200) {
          setClients(data.data);
          setIsLoading(false)// Set clients from the response
        }
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClientChat = async (identifier, client_id) => {
    try {
      const response = await fetch(`https://integrations.uwc.world/api/offline_chat/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
         // identifier: identifier
          from_id: currentUser?.UserId,
          to_id: Number(clientID),
        })
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 200) {
          if (data?.data?.length > 0) {
            setChats(data?.data)
          }
        }
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendClientChat = async () => {
    try {
      const response = await fetch(`https://integrations.uwc.world/api/offline_chat/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to_id: parseInt(newClient?.to_id, 10) || selectedClient?.to_id,
          from_id: currentUser?.UserId,
          message: newMessage,
          appointment_id: ""
        })
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 200) {

          const searchParams = new URLSearchParams(location.search);
          const type = searchParams.get("type");
          if (type === "redirect") {
            setChats((prev) => [...prev,
            {
              timestamp: new Date().toISOString(),
              message: newMessage,
              appointment_id: 12625,
              from_id: currentUser?.UserId,
              to_id: newClient?.to_id,
              identifier: ""
            },

            ]);
          } else {
            fetchClientChat();
          }
          setNewMessage("");
        }
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      console.error(error);
    }
  };




  useEffect(() => {
    if (currentUser?.UserId) {
      fetchClients();
    }
  }, [currentUser?.UserId,]);

  useEffect(() => {
   
    const type = searchParams.get("type");
    if (clientID && clientName) {
      const client = clients?.find(client => client?.to_id === parseInt(clientID, 10));

    //  console.log("client ===>", client)
      if (client && type !== "redirect") {
        setSelectedClient(client);
        if (client?.messages?.[0]?.identifier) {
          fetchClientChat()
          setIdentifier(client?.messages?.[0]?.identifier)
          setClientUserID(client?.to_id)
        } else {
          setChats(client?.messages)
        }
      } else if (client) {
        if (client?.messages?.[0]?.identifier) {
          fetchClientChat()
          setIdentifier(client?.messages?.[0]?.identifier)
          setClientUserID(client?.to_id)
        } else {
          setChats(client?.messages)
        }
        setNewClient({
          to_id: clientID,
          clientName: clientName
        })
      } else {
        setNewClient({
          to_id: clientID,
          clientName: clientName
        })
      }
    } else {
      setNewClient(null)
    }


  }, [navigate, location, clients])



  useEffect(() => {
    let intervalId;

    if (clientID) {
      intervalId = setInterval(() => {
        fetchClientChat();
      }, 1000 * 60 * 2); // Every 2 minutes
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear the interval
      }
    };
  }, [clientID]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  // Handle selecting a client to load their chat
  const handleClientSelect = (client) => {
    setSelectedClient(client);

    if (client?.messages?.[0]?.identifier) {
      fetchClientChat()
      setIdentifier(client?.messages?.[0]?.identifier)
    } else {
      setChats(client?.messages)
    }
  };
  // Handle sending a new message (not implemented, but placeholder for future)
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendClientChat()

    }
  };
  const scrollToBottom = () => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Box display="flex" flexDirection="row" height="85vh" bgcolor="#f5f5f5">
      {/* Left side: Client list */}
      {!newClient?.to_id && <Box width="30%" height={"100%"} borderRight="1px solid #ddd" overflow="auto" bgcolor="#fff" boxShadow={2}>
        <Typography variant="h6" padding={2} borderBottom={1} align="start" sx={{ color: "#73589b", fontWeight: "bold" }}>
          Clients
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : null}
        {!isLoading && clients?.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>No Clients Yet</Typography>
          </Box>
        ) : (
          <List sx={{ padding: 0 }}>
            {clients?.map((client) => (
              <ListItem
                button
                key={client?.to_id}
                onClick={() => navigate(`/expert/chat?clientID=${client?.to_id}&clientName=${client?.user_details?.name}`)}
                sx={{
                  '&:hover': {
                    backgroundColor: '#73589b30',
                  },
                  backgroundColor: selectedClient === client ? '#73589b8c' : 'transparent',
                  color: selectedClient === client ? 'white' : 'black',
                }}
              >
                <Avatar
                  src={client?.user_details?.image}
                  alt={client?.user_details?.name}
                  sx={{ marginRight: 2 }}

                />
                <ListItemText
                  sx={{ color: selectedClient === client ? 'white' : 'black', }}
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {client?.user_details?.name}
                    </Typography>
                  }
                  secondary={client?.user_details?.email}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>}

      {/* Right side: Chat window */}
      {!newClient?.to_id && <Box width="70%" paddingX={2} paddingTop={2} display="flex" flexDirection="column" bgcolor="#f9f9f9">
        {selectedClient ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', color: '#73589b', paddingBottom: 2, borderBottom: '1px solid #ddd' }}
            >
              {selectedClient.user_details.name}
            </Typography>

            {/* Chat messages */}
            <Paper
              elevation={3}
              sx={{
                flex: 1,
                padding: 3,
                overflowY: "auto",
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
              }}
            >
              {chats?.length === 0 ? (
                <div className="NoDataAvailable">
                  {/* {NoDataSvg} */}
                  <Typography>No messages Yet</Typography>
                </div>
              ) : (
                chats?.reduce((acc, chat, index, arr) => {
                  const currentChatDate = new Date(chat?.timestamp).toLocaleDateString();
                  const previousChatDate = index > 0 ? new Date(arr[index - 1]?.timestamp).toLocaleDateString() : null;

                  if (currentChatDate !== previousChatDate) {
                    acc.push(
                      <Typography
                        key={`date-${index}`}
                        variant="caption"
                        sx={{
                          textAlign: "center",
                          display: "block",
                          marginBottom: 1,
                          color: "gray"
                        }}
                      >
                        {currentChatDate}
                      </Typography>
                    );
                  }

                  acc.push(
                    <Box key={index} mb={2} display="flex" flexDirection="column">
                      <Typography
                        variant="body2"
                        sx={{
                          color: chat?.from_id === currentUser?.UserId ? "#73589b" : "textSecondary",
                          fontWeight: chat?.from_id === currentUser?.UserId ? '600' : '500',
                          backgroundColor: chat?.from_id === currentUser?.UserId ? "#73589b15" : "#f1f1f1",
                          padding: 1,
                          borderRadius: 1,
                          maxWidth: "70%",
                          alignSelf: chat.from_id === currentUser?.UserId ? 'flex-end' : 'flex-start',
                        }}
                      >
                        {chat?.message}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        align={chat?.from_id === currentUser?.UserId ? 'right' : 'left'}
                      >

                        {dayjs.utc(chat?.timestamp).local().format('hh:mm A')}
                      </Typography>
                    </Box>
                  );

                  return acc;
                }, [])
              )}
              <div ref={chatRef}></div>
            </Paper>


            {/* Input for new message */}
            <Box display="flex" gap={2} alignItems={"center"} mt={2}>
              <TextField
                fullWidth
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                sx={{ backgroundColor: "#fff", borderRadius: 1 }}
                onKeyDown={(e) => {

                  if (e && e.code === "Enter" && !e.shiftKey) {
                    e.preventDefault();

                    handleSendMessage();

                  }
                }}
              />
              <Button
                onClick={handleSendMessage}
                variant="contained"
                className="PrimaryCTAExp"

                disabled={newMessage.trim() === ""}
              >
                Send
              </Button>
            </Box>
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <div className="NoDataAvailable">
              {/* {NoDataSvg} */}
              <Typography>Select a client to start chat</Typography>
            </div>
          </Box>
        )}



      </Box>}
      {newClient?.to_id &&
        <Box width="100%" paddingX={2} paddingTop={2} display="flex" flexDirection="column" bgcolor="#f9f9f9">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#73589b', paddingBottom: 2, borderBottom: '1px solid #ddd' }}
          >
            <IconButton onClick={() => { setNewClient(null); setChats([]); navigate('/expert/chat'); fetchClients() }}>
              <ArrowBack />
            </IconButton>  {newClient?.clientName}
          </Typography>

          {/* Chat messages */}
          <Paper
            elevation={3}
            sx={{
              flex: 1,
              padding: 3,
              overflowY: "auto",
              borderRadius: 2,
              backgroundColor: "#fff",
              boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
            }}
          >
            {chats?.length === 0 ? (
              <div className="NoDataAvailable">
                {/* {NoDataSvg} */}
                <Typography>No messages Yet</Typography>
              </div>
            ) : (
              chats?.reduce((acc, chat, index, arr) => {
                const currentChatDate = new Date(chat?.timestamp).toLocaleDateString();
                const previousChatDate = index > 0 ? new Date(arr[index - 1]?.timestamp).toLocaleDateString() : null;

                if (currentChatDate !== previousChatDate) {
                  acc.push(
                    <Typography
                      key={`date-${index}`}
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        display: "block",
                        marginBottom: 1,
                        color: "gray"
                      }}
                    >
                      {currentChatDate}
                    </Typography>
                  );
                }

                acc.push(
                  <Box key={index} mb={2} display="flex" flexDirection="column">
                    <Typography
                      variant="body2"
                      sx={{
                        color: chat?.from_id === currentUser?.UserId ? "#73589b" : "textSecondary",
                        fontWeight: chat?.from_id === currentUser?.UserId ? '600' : '500',
                        backgroundColor: chat?.from_id === currentUser?.UserId ? "#73589b15" : "#f1f1f1",
                        padding: 1,
                        borderRadius: 1,
                        maxWidth: "70%",
                        alignSelf: chat.from_id === currentUser?.UserId ? 'flex-end' : 'flex-start',
                      }}
                    >
                      {chat?.message}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      align={chat?.from_id === currentUser?.UserId ? 'right' : 'left'}
                    >
                      {dayjs.utc(chat?.timestamp).local().format('hh:mm A')}
                    </Typography>
                  </Box>
                );

                return acc;
              }, [])
            )}
            <div ref={chatRef}></div>
          </Paper>


          {/* Input for new message */}
          <Box display="flex" gap={2} alignItems={"center"} mt={2}>
            <TextField
              fullWidth
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
              onKeyDown={(e) => {

                if (e && e.code === "Enter" && !e.shiftKey) {
                  e.preventDefault();

                  handleSendMessage();

                }
              }}
            />
            <Button
              onClick={() => { handleSendMessage() }}
              variant="contained"
              className="PrimaryCTAExp"

              disabled={newMessage.trim() === ""}
            >
              Send
            </Button>
          </Box>
        </Box>}
    </Box >
  );
}

export default ExpertChatDB;
