import { Routes, Route, BrowserRouter, useLocation, matchPath } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/Home";
import LandingPage from "../pages/LandingPage";
import ExpertDetails from "../pages/ExpertDetails";
import SignIn from "../pages/SignIn";
import BookAnAppointment from "../pages/BookAnAppointment";
import Dashboard from "../pages/dashboard";
import ExpertDashboard from "../pages/expertDashboard";
import About from "../pages/About";
import PaymentStatus from "../pages/PaymentStatus";
import TermAndCondition from "../components/TermAndCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclaimer from "../components/Disclaimer";
import CoursePaymentStatus from "../pages/CoursePaymentStatus";
import ExpertBookingPreview from "../pages/ExpertBookingPreview";
import CourseOrderPreviewPage from "../pages/CourseOrderPreviewPage";
import ScrollToTop from "../ScrollToTop";
import Stella from "../pages/Stella";
import SubscriptionUpgrade from "../components/subscriptions/SubscriptionUpgrade";
import SubscriptionPlans from "../components/subscriptions/SubscriptionPlans";
import CancelSubscriptions from "../components/subscriptions/CancelSubscriptions";
import CancelSubscriptionNote from "../components/subscriptions/CancelSubscriptionNote";
import SubscriptionOrderSummary from "../components/subscriptions/SubscriptionOrderSummary";
import MySubscription from "../components/subscriptions/MySubscription";
import OnboardingStella from "../pages/OnboardingStella";
import Listing from "../components/course/Listing";
import ThankYou from "../components/subscriptions/ThankYou";
import TransactionFailed from "../components/subscriptions/TransactionFailed";
import Callback from "../components/Callback";
import NewPlansPage1 from "../components/subscriptions/NewPlansPage-1";
import PlanDetailsPage from "../components/subscriptions/PlanDetailsPage";
import Course from "../components/courseNew/Course";
import Main from "../components/dashboard/Components/Dashboard/Main";
import MyPrograms from "../components/dashboard/Components/My programs/My_programs";
import SignIn2 from "../components/SignIn2";
import SignUp2 from "../components/SignUp2";
import CourseInternalRouting from "./CourseInternalRouting";
import DeleteAccount from "../pages/DeleteAccount";
import DashboardRouter from "../pages/USonBoardingDashboard";
import Community from "../components/Community/Community";
import ClubDetail from "../components/Community/ClubDetail";
import CategoryDetail from "../components/Community/CategoryDetail";
import DetailPage from "../components/Community/DetailPage";
import AssessmentList from "../pages/AssessmentList";
import StellaTestingJourney from "../pages/StellaTestingJourney";
import UserProfile from "../components/Community/UserProfile";
import Search from "../components/Search/Search";
import Success from "../components/BookingJourney/Component/Success";
import { useEffect } from "react";

// const REDIRECT_ROUTES = [
//   "/",
//   "/profession/:profession/:expertName/:id",
//   // "/payment-order-preview/:id",
//   // "/appointment-confirmed",
//   // "/payment-status",
// ];  

const REDIRECT_ROUTES = [
  "/", // etc.
  "/profession/:profession/:expertName/:id",
  // ...
];

// 1. Create a map of old -> new dashboard URLs
const DASHBOARD_REDIRECT_MAP = {
  "/user-dashboard": "https://dashboard.unitedwecare.com",
  "/dashboard/my-appointments": "https://dashboard.unitedwecare.com/appointments",
  "/dashboard/program/my-programs": "https://dashboard.unitedwecare.com/programs",
  "/assessment-list": "https://assessments.unitedwecare.com/",
};

const RedirectHandler = () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;

    // --- 1) Handle your existing REDIRECT_ROUTES logic ---
    const shouldRedirect = REDIRECT_ROUTES.some((route) =>
      matchPath(route, pathname)
    );

    if (shouldRedirect) {
      const params = new URLSearchParams(search);
      const newParams = new URLSearchParams();

      for (const [key, value] of params.entries()) {
        if (value && value.toLowerCase() !== "undefined" && value !== "") {
          newParams.append(key.toLowerCase(), value);
        }
      }

      const queryString = newParams.toString();
      const newUrl = `https://booking.unitedwecare.com${pathname}${queryString ? `?${queryString}` : ""
        }`;

      window.location.href = newUrl;
      return; // Important: stop here if we've already redirected
    }

    // --- 2) Check if the path is in our DASHBOARD_REDIRECT_MAP ---
    //    Find a matching key, accounting for route parameters if needed
    const matchedDashboardPath = Object.keys(DASHBOARD_REDIRECT_MAP).find(
      (route) => matchPath(route, pathname)
    );

    if (matchedDashboardPath) {
      // If you want to preserve query params, you can append them here:
      const newUrl = DASHBOARD_REDIRECT_MAP[matchedDashboardPath];
      window.location.href = newUrl;
    }
  }, [location]);

  return null;
};


// const RedirectHandler = () => {
//   const location = useLocation();

//   useEffect(() => {
//     const { pathname, search } = location;

//     // Check if the current path matches any of our redirect routes using matchPath
//     const shouldRedirect = REDIRECT_ROUTES.some((route) =>
//       matchPath(route, pathname)
//     );

//     if (shouldRedirect) {
//       const params = new URLSearchParams(search);
//       const newParams = new URLSearchParams();

//       // Convert all query parameters to lowercase and filter out undefined/empty values
//       for (const [key, value] of params.entries()) {
//         if (value && value.toLowerCase() !== 'undefined' && value !== '') {
//           newParams.append(key.toLowerCase(), value);
//         }
//       }

//       // Construct new URL with lowercase query params, only adding ? if we have valid params
//       const queryString = newParams.toString();
//       const newUrl = `https://booking.unitedwecare.com${pathname}${queryString ? `?${queryString}` : ''
//         }`;

//       window.location.href = newUrl;
//     }
//   }, [location]);

//   return null;
// };


const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RedirectHandler />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/privacy/request" element={<DeleteAccount />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/landing-page" element={<LandingPage />} />

          {/* Expert appointment Booking route */}
          <Route path="/" element={<Home />} />
          <Route
            path="/profession/:profession/:expertName/:id"
            element={<ExpertDetails />}
          />
          <Route
            path="/book-an-appointment/:id"
            element={<BookAnAppointment />}
          />
          <Route
            path="/payment-order-preview/:id"
            element={<ExpertBookingPreview />}
          />
          <Route path="/appointment-confirmed/:id" element={<Success />} />
          {/* Expert appointment Booking route */}

          <Route path="/payment-status/:id" element={<PaymentStatus />} />
          <Route path="/terms-and-conditions" element={<TermAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />

          <Route path="/program" element={<Listing />} />
          <Route path="/program/:name/:id" element={<Course />} />
          <Route
            path="/program-internal/*"
            element={<CourseInternalRouting />}
          />
          <Route
            path="/program/payment-status/:id"
            element={<CoursePaymentStatus />}
          />

          <Route
            path="/program/program-order-preview/:id"
            element={<CourseOrderPreviewPage />}
          />

          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/expertdashboard/*" element={<ExpertDashboard />} />
          {/* <Route path="/login" element={<SignIn />} />
          <Route path="/login1" element={<SignIn2 />} /> */}
          <Route path="/create-account" element={<SignUp2 />} />
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
          <Route
            path="/subscription-upgrade"
            element={<SubscriptionUpgrade />}
          />
          <Route
            path="/cancel-subscription"
            element={<CancelSubscriptions />}
          />
          <Route
            path="/cancel-subscription-note"
            element={<CancelSubscriptionNote />}
          />
          <Route
            path="/subscription-order-summary/"
            element={<SubscriptionOrderSummary />}
          />
          <Route path="/my-subscription" element={<MySubscription />} />
          <Route path="/onboarding-stella" element={<OnboardingStella />} />
          <Route path="/stella" element={<Stella />} />
          <Route path="/plans" element={<NewPlansPage1 />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/payment-success" element={<ThankYou />} />
          <Route path="/payment-failed" element={<TransactionFailed />} />
          <Route
            path="/platinum-care-plan"
            element={<PlanDetailsPage type="Platinum" />}
          />
          <Route
            path="/premium-care-plan"
            element={<PlanDetailsPage type="Premium" />}
          />
          <Route
            path="/super-care-plan"
            element={<PlanDetailsPage type="Super" />}
          />
          <Route path="/callback" element={<Callback />} />

          <Route path="/user-dashboard" element={<Main />} />

          <Route path="/My-programs" element={<MyPrograms />} />

          {/* Community Routes */}
          <Route path="/community" element={<Community />} />
          <Route path="/community/club/:id" element={<ClubDetail />} />
          <Route path="/community/category/:id" element={<CategoryDetail />} />
          <Route path="/community/feed/blog/:id" element={<DetailPage />} />
          <Route path="/community/feed/video/:id" element={<DetailPage />} />
          <Route path="/community/feed/podcast/:id" element={<DetailPage />} />
          <Route path="/community/user/:id" element={<UserProfile />} />


          <Route path='/assessment-list' element={<AssessmentList />} />

          {/* Search Routes */}
          <Route path='/search' element={<Search />} />
        </Route>
        {/* Expert Dashboard Route */}
        <Route path="/expert/*" element={<DashboardRouter />} />
        <Route path="/static-stella" element={<StellaTestingJourney />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
