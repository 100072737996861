import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Switch from "@mui/material/Switch";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//JWT
import jwtDecode from "jwt-decode";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

import { useAuth } from "../../../auth";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RaiseTicketModal = ({ postTicketsData }) => {
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectIssue, setSelectIssue] = useState("10");
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [ticketSubjectList, setTicketSubjectList] = useState([]);

  const [ticketEmail, setTicketEmail] = useState("");
  const [ticketSubject, setTicketSubject] = useState();
  const [ticketMessage, setTicketMessage] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFileLoading, setIsFileLoading] = useState(null);

  const [isTicketSent, setIsTicketSent] = useState(false);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };
  const userID = JSON.parse(localStorage.getItem("profile")).UserId;
  const { currentUser, userType, logout } = useAuth();

  useEffect(() => {
    const payload = {
      user_id: userID,
    };

    const getSubjectsList = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/ticket-subjects`,
          {
            method: "GET",
            headers,
          }
        );
        const postResponse = await response.json();

        const { subjects } = await postResponse.data;

        setTicketSubjectList(subjects);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getSubjectsList();
  }, []);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setIsFileLoading(true);
    const validTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
    ];

    if (validTypes.includes(file.type)) {
      try {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // You can use percent for progress indication if needed
          },
          (error) => {
            setIsFileLoading(false);
            enqueueSnackbar("Error uploading file: " + error.message, {
              variant: "error",
            });
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              setSelectedFiles(url);
              setIsFileLoading(false);
              enqueueSnackbar("File uploaded successfully", {
                variant: "success",
              });
            } catch (error) {
              setIsFileLoading(false);
              enqueueSnackbar("Error getting download URL", {
                variant: "error",
              });
            }
          }
        );
      } catch (error) {
        setIsFileLoading(false);
        enqueueSnackbar("Error uploading file", { variant: "error" });
      }
    } else {
      setIsFileLoading(false);
      enqueueSnackbar("Please upload only PNG, JPG, JPEG or PDF files", {
        variant: "error",
      });
    }
  };

  //Ticket

  const handleTicketSubject = (e) => {
    setTicketSubject(e.target.value);
  };

  const handleTicketMessage = (e) => {
    setTicketMessage(e.target.value);
  };

  const handleSendTicket = async () => {
    setIsTicketSent(true);
    const payload = {
      user_id: userID,
      mail: currentUser?.Emailid,
      subject_id: ticketSubject,
      description: ticketMessage,
      file: selectedFiles,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/raise-ticket`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      if (postResponse?.code === 200) {
        postTicketsData();
        setIsTicketSent(false);
        setSelectedFiles(null);
        setTicketEmail("");
        setTicketSubject();
        setTicketMessage("");
        setOpen(false);
        enqueueSnackbar(postResponse?.data?.message, { variant: "success" });
        setOpenSnackbar(true);
      }
    } catch (error) {
      setIsTicketSent(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        className="PrimaryCTA"
        disableElevation
      >
        <AddCircleIcon />
        Contact Support
      </Button>

      <Dialog
        className="AddApointment--Dialog"
        open={open}
        onClose={handleClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isTicketSent && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}

        <DialogTitle>Contact Support</DialogTitle>
        <p
          style={{
            padding: "0 24px",
            color: "#656565",
            fontSize: "16px",
            lineHeight: "130%",
          }}
        >
          Please raise your ticket here. Our support team will reach back to you
          soon.
        </p>
        <DialogContent className="Dialog--Content">
          <FormControl
            sx={{
              background: "#FFF",
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{ background: "#FFF", padding: "0 5px" }}
            >
              Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ticketSubject}
              onChange={handleTicketSubject}
              fullWidth
            >
              {ticketSubjectList?.map((item, i) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="outlined-multiline-static"
            label="Explain in few words"
            multiline
            fullWidth
            rows={4}
            value={ticketMessage}
            onChange={handleTicketMessage}
            sx={{ marginTop: "15px" }}
          />

          <div className="upload-button">
            <label htmlFor="file-upload" className="upload-button-label">
              <FileUploadOutlinedIcon className="upload-button-icon" />
              Upload Files
            </label>
            {isFileLoading !== null && isFileLoading ? (
              <div className="d-flex align-items-center mx-auto">
                <CircularProgress
                  sx={{ width: "20px !important", height: "20px !important" }}
                />
              </div>
            ) : (
              <>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  className="upload-button-input"
                />
                {selectedFiles && (
                  <div className="selected-files">
                    <div className="file-name">{selectedFiles}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="ConfirmBooking"
            sx={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={handleSendTicket}
            variant="contained"
            disableElevation
            disabled={ticketMessage == ""}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RaiseTicketModal;
