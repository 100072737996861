import { useState, useEffect, forwardRef } from "react";
import {
  Grid,
  TextField,
  Checkbox,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Snackbar,
  CircularProgress,
  Skeleton,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { storage } from "../../../../firebaseConfig";
import { useSnackbar } from "notistack";

// API Configuration
const API_CONFIG = {
  headers: {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  },
  baseURL: "https://us.uwc.world/new-uwc",
};

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const EditProfessionalProfile = ({ data = {} }) => {
  // Destructure data with defaults
  const {
    register_as_id = "",
    language_ids = [],
    experience = "",
    documents = [],
    area_of_interest_ids = [],
    about_experience = "",
    school = "AIT School of Mental Sciences",
    graduation_year = 4,
    treatment_ids = [],
    mental_disorders_ids = [],
    licensed_state_ids = [],
    license_type_id = [],
    license_type = "",
    license_expiry_date = "",
    license = "",
  } = data;

  const { enqueueSnackbar } = useSnackbar();
  // State management
  const [state, setState] = useState({
    selectedTreatments: treatment_ids,
    selectedMentalDisorders: mental_disorders_ids,
    // specifyOthers: ,
    aboutExperience: about_experience,
    school: school,
    registeredAsList: [],
    registeredAs: "",
    registeredAsFields: null,
    languageList: [],
    areasOfInterestList: [],
    languageIDs: language_ids,
    areaOfInterests: area_of_interest_ids,
    selectedFiles: [],
    openSnackbar: false,
    snackbarMessage: "",
    isUpdating: false,
    selectedStates: licensed_state_ids,
    licenseType: license_type_id,
    license_type: license_type,
    license_expiry_date: license_expiry_date,
    license: license,
  });

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [proofIDList, setproofIDList] = useState([]);
  const userID = JSON.parse(localStorage.getItem("profile")).UserId;
  const [treatmentList, setTreatmentList] = useState([]);
  const [mentalDisordersList, setMentalDisordersList] = useState([]);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);

  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  // API calls
  const fetchData = async (endpoint, method = "GET", body = null) => {
    try {
      const response = await fetch(`${API_CONFIG.baseURL}${endpoint}`, {
        method,
        headers: API_CONFIG.headers,
        ...(body && { body: JSON.stringify(body) }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching from ${endpoint}:`, error);
      return null;
    }
  };

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [registeredAsResponse, languagesResponse, areaInterestResponse] =
          await Promise.all([
            fetchData("/registration/registeras"),
            fetchData("/languages"),
            fetchData("/area-interest", "POST", { register_as_id }),
          ]);

        // console.log(
        //   "Response data from expert professional details:---",
        //   registeredAsResponse,
        //   languagesResponse,
        //   areaInterestResponse
        // );

        setState((prev) => ({
          ...prev,
          registeredAsList: registeredAsResponse?.data?.register_as || [],
          languageList: languagesResponse?.data?.languages || [],
          areasOfInterestList:
            areaInterestResponse?.data?.area_of_interests || [],
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [register_as_id]);

  // Update registered as fields when register_as_id changes
  useEffect(() => {
    const professionItem = state.registeredAsList?.find(
      (item) => item.id == register_as_id
    );

    setState((prev) => ({
      ...prev,
      registeredAs: professionItem?.name || "",
      registeredAsFields: professionItem?.fields,
    }));
  }, [register_as_id, state.registeredAsList]);

  // Event handlers
  const handleStateChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdateProfessionalProfile = async () => {
    handleStateChange("isUpdating", true);

    const payload = {
      uwc_user_id: userID,
      about_experience: state.aboutExperience,
      area_of_interest_ids: state.areaOfInterests,
      treatment_ids: state.selectedTreatments,
      language_ids: state.languageIDs,
      licensed_state_ids: state.selectedStates,
      license_type_id: state.licenseType,
      mental_disorders_ids: state.selectedMentalDisorders,
      license_type: state.license_type,
      license_expiry_date: state.license_expiry_date,
      license: state.license,
    };

    try {
      const response = await fetchData(
        "/expert/dashboard/update-professional-detail",
        "POST",
        payload
      );

      if (response?.code === 200) {
        handleStateChange("snackbarMessage", response?.data?.message);
        handleStateChange("openSnackbar", true);
      }
    } finally {
      handleStateChange("isUpdating", false);
    }
  };

  // Helper function for file names
  const getFileNames = (url) => {
    const fileNameWithPrefix = url.substring(
      url.lastIndexOf("/") + 1,
      url.indexOf("?")
    );
    return decodeURIComponent(fileNameWithPrefix.replace(/%20/g, " ")).replace(
      "June_2023/",
      ""
    );
  };

  // Add skeleton loader component
  const InputSkeleton = () => (
    <Skeleton variant="rectangular" height={56} sx={{ borderRadius: 1 }} />
  );

  //Countries List
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://us.uwc.world/new-uwc/country", {
          method: "GET",
          headers,
        });
        const postResponse = await response.json();
        if (postResponse.code === 200) {
          setCountryList(postResponse.data.countries);
          fetchStates("IN");
          fetchIDs();
        } else {
          fetchStates("IN");
          fetchIDs();
        }
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchCountries();
  }, []);

  // Fetch Treatment list
  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const headers = {
          authorization:
            "Bearer a4b56c0c1ffd4040d33156fea0bdcfd3111ba495715aa0bcc9ede1f5dc703c2c38011",
          "content-type": "application/json",
          "x-api-key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          "x-email-id": "kareifyexpert@gmail.com",
        };

        const response = await fetch(
          "https://us.uwc.world/new-uwc/treatments",
          {
            method: "POST",
            headers,
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTreatmentList(data?.data?.treatments || []);
      } catch (error) {
        console.error("Error fetching treatments:", error);
        setTreatmentList([]);
      }
    };

    fetchTreatments();
  }, []);

  // Fetch Mental Disorders list
  useEffect(() => {
    const fetchMentalDisorders = async () => {
      try {
        const headers = {
          "content-type": "application/json",
          "x-api-key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          "x-email-id": "kareifyexpert@gmail.com",
        };

        const response = await fetch(
          "https://us.uwc.world/new-uwc/mental-disorders",
          {
            method: "POST",
            headers,
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setMentalDisordersList(data?.data?.mental_disorders || []);
      } catch (error) {
        console.error("Error fetching mental disorders:", error);
        setMentalDisordersList([]);
      }
    };

    fetchMentalDisorders();
  }, []);

  // Fetch State list based on country
  const fetchStates = async (countryCode) => {
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/state/${countryCode}`,
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();
      const statesArray = await postResponse.data?.states;

      setStateList(statesArray);
      // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  // Fetch ID Proofs
  const fetchIDs = async () => {
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/registration/id-proofs`,
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();

      setproofIDList(postResponse?.data.id_proofs);
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleLicenseUpload = async (event) => {
    setUploadFileLoading(true);
    try {
      const file = event.target.files[0];

      // Validate if file is selected
      if (!file) return;

      // Validate file type
      if (file.type !== "application/pdf") {
        toast.error("Please upload only PDF documents");
        return;
      }

      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size should be less than 5MB");
        return;
      }

      // Show loading state
      toast.info("Uploading document...");

      // Create unique filename
      const timestamp = new Date().getTime();
      const fileName = `licenses/${timestamp}_${file.name}`;

      // Create storage reference
      const storageRef = ref(storage, fileName);

      // Upload file
      const snapshot = await uploadBytes(storageRef, file);

      // Get download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Update state with the new URL
      setUploadedFiles(downloadURL);
      handleStateChange("license", downloadURL);

      setUploadFileLoading(false);
      enqueueSnackbar("Document uploaded successfully", {
        variant: "success",
      });
    } catch (error) {
      setUploadFileLoading(false);
      console.error("Error uploading document:", error);
      toast.error("Failed to upload document. Please try again.");
    }
  };

  // Render methods remain the same as they're already well-structured
  return (
    <>
      <div className="Content--Section">
        <h6>Professional Profile</h6>
        {isLoading ? (
          <Skeleton width={200} />
        ) : (
          <span>
            You are registered as a <b>{state.registeredAs}</b>
          </span>
        )}

        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {/* Language */}
          <Grid item xs={4}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <FormControl fullWidth>
                <InputLabel sx={{ background: "#fff" }}>Language</InputLabel>
                <Select
                  fullWidth
                  multiple
                  value={state.languageIDs}
                  label="Language"
                  onChange={(event) =>
                    handleStateChange("languageIDs", event.target.value)
                  }
                  renderValue={(selected) =>
                    selected
                      .map(
                        (id) =>
                          state.languageList.find((lang) => lang.id === id)
                            ?.name
                      )
                      .join(", ")
                  }
                >
                  {state.languageList.map((lang) => (
                    <MenuItem key={lang.id} value={lang.id}>
                      <Checkbox
                        checked={state.languageIDs.includes(lang.id)}
                        name={lang.name}
                      />
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          {/* Experience */}
          <Grid item xs={4}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <TextField
                fullWidth
                id="experience-text"
                label="Experience"
                variant="outlined"
                value={`${experience ? experience : 0} years`}
                disabled
              />
            )}
          </Grid>

          {/* School */}
          <Grid item xs={8}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <TextField
                id="school"
                label="School"
                value={state.school}
                variant="outlined"
                fullWidth
                disabled
              />
            )}
          </Grid>

          {/* Graduation/Doctorate Year */}
          <Grid item xs={4}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <TextField
                id="grad-year"
                label="Graduation/Doctorate Year"
                value={graduation_year}
                variant="outlined"
                fullWidth
                disabled
              />
            )}
          </Grid>
        </Grid>
      </div>

      <hr />
      <div className="Content--Section">
        {/* Uploaded Files */}
        <h6>UploadedFiles</h6>
        <div className="UploadedFiles--Section mb-3">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <TextField
              fullWidth
              id="uploaded-basic"
              label="Uploaded Files"
              variant="outlined"
              value={documents.map(getFileNames).join(", ")}
              className="mt-2"
            />
          )}
        </div>

        {/* Areas of interest */}
        <FormControl fullWidth sx={{ marginTop: "15px" }}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <>
              <InputLabel sx={{ background: "#fff" }}>
                Areas of interest(multiple selection allowed)
              </InputLabel>
              <Select
                fullWidth
                multiple
                value={state.areaOfInterests}
                label="Areas of interest(multiple selection allowed)"
                onChange={(e) =>
                  handleStateChange("areaOfInterests", e.target.value)
                }
                renderValue={(selected) => {
                  const selectedInterests = selected.map(
                    (id) =>
                      state.areasOfInterestList.find(
                        (interest) => interest.id === id
                      )?.name
                  );
                  return selectedInterests.join(", ");
                }}
              >
                {state.areasOfInterestList.map((interest) => (
                  <MenuItem key={interest.id} value={interest.id}>
                    <Checkbox
                      checked={state.areaOfInterests.includes(interest.id)}
                      name={interest.name}
                    />
                    {interest.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>

        <div className="Content--Section mt-3 pt-3">
          <Grid container spacing={2}>
            {/* Licensed State */}
            <Grid item xs={4}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <FormControl fullWidth>
                  <InputLabel sx={{ background: "#fff" }}>
                    State where you are licensed to practice?
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    labelId="state-select-label"
                    id="state-select"
                    value={state.selectedStates || []}
                    label="State where you are licensed to practice?"
                    onChange={(e) =>
                      handleStateChange("selectedStates", e.target.value)
                    }
                  >
                    {stateList?.map((item) => {
                      return (
                        <MenuItem key={item.code} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>

            {/* License Type */}
            <Grid item xs={4}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <FormControl fullWidth>
                  <InputLabel sx={{ background: "#fff" }}>
                    Choose License Type
                  </InputLabel>
                  <Select
                    fullWidth
                    value={state.licenseType || []}
                    label="Choose License Type"
                    onChange={(e) =>
                      handleStateChange("licenseType", e.target.value)
                    }
                  >
                    {proofIDList?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            {/* Specify License Type */}
            <Grid item xs={4}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <TextField
                  id="license-type"
                  label="Specify License Type"
                  variant="outlined"
                  fullWidth
                  value={state.license_type || ""}
                  onChange={(e) =>
                    handleStateChange("license_type", e.target.value)
                  }
                />
              )}
            </Grid>

            {/* License Expiry Date */}
            <Grid item xs={4}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of expiration"
                    value={
                      state.license_expiry_date
                        ? dayjs(state.license_expiry_date)
                        : null
                    }
                    onChange={(newValue) => {
                      // Check if newValue exists and is valid
                      const formattedDate =
                        newValue && newValue.isValid()
                          ? newValue.format("YYYY-MM-DD")
                          : null;
                      handleStateChange("license_expiry_date", formattedDate);
                    }}
                    sx={{ width: "100%", paddingTop: "0" }}
                    minDate={dayjs()} // Use dayjs for minDate
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            </Grid>

            {/* License Upload */}
            <Grid item xs={8}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <div
                  className="upload-button"
                  style={{ marginTop: "0", height: "100%" }}
                >
                  <input
                    type="file"
                    id="file-upload"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={handleLicenseUpload}
                  />
                  <label
                    htmlFor="file-upload"
                    className="upload-button-label"
                    style={{
                      height: "100%",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <FileUploadOutlinedIcon className="upload-button-icon" />
                    {state.license ? "Update License" : "Upload License"}
                  </label>
                  {uploadFileLoading ? (
                    <Box sx={{ mt: 1 }}>
                      <Skeleton
                        animation="wave"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          height: 40,
                          width: "250px",
                        }}
                      />
                    </Box>
                  ) : uploadedFiles !== null ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <InsertDriveFileIcon />
                      <Link
                        href={uploadedFiles}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: "none" }}
                      >
                        View Current Uploading License Document
                      </Link>
                    </Box>
                  ) : (
                    state?.license && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <InsertDriveFileIcon />
                        <Link
                          href={state.license}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ textDecoration: "none" }}
                        >
                          View License Document
                        </Link>
                      </Box>
                    )
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      <hr />

      <div className="Content--Section mt-3">
        <Grid container spacing={2}>
          {/* Treatment */}
          <Grid item xs={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <FormControl fullWidth sx={{ marginTop: "15px" }}>
                <InputLabel sx={{ background: "#fff" }}>
                  What do you treat?
                </InputLabel>
                <Select
                  fullWidth
                  multiple
                  value={state.selectedTreatments || []}
                  label="What do you treat?"
                  onChange={(e) =>
                    handleStateChange("selectedTreatments", e.target.value)
                  }
                >
                  {treatmentList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          {/* Mental Disorders */}
          <Grid item xs={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <FormControl fullWidth sx={{ marginTop: "15px" }}>
                <InputLabel sx={{ background: "#fff" }}>
                  What mental disorders do you treat?
                </InputLabel>
                <Select
                  fullWidth
                  multiple
                  value={state.selectedMentalDisorders || []}
                  label="What mental disorders do you treat?"
                  onChange={(e) =>
                    handleStateChange("selectedMentalDisorders", e.target.value)
                  }
                >
                  {mentalDisordersList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          {/* Specify Others */}
          {/* <Grid item xs={4}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <TextField
                sx={{
                  marginTop: "15px",
                }}
                fullWidth
                id="outlined-basic"
                label="Specify Others"
                value={state.specifyOthers}
                onChange={(e) =>
                  handleStateChange("specifyOthers", e.target.value)
                }
                variant="outlined"
                placeholder="Please specify other treatments or disorders"
              />
            )}
          </Grid> */}
        </Grid>

        {/* About Experience */}
        <div className="Content--Section mt-5">
          <span>
            Write about your professional experience and specialization
          </span>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              height={132}
              sx={{ borderRadius: 1, marginTop: "15px" }}
            />
          ) : (
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label=""
              multiline
              rows={4}
              value={state.aboutExperience}
              onChange={(e) =>
                handleStateChange("aboutExperience", e.target.value)
              }
              defaultValue=""
              sx={{ marginTop: "15px" }}
            />
          )}
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width={200}
              height={36}
              sx={{ borderRadius: 1, marginLeft: "auto", marginTop: "15px" }}
            />
          ) : (
            <Button
              variant="contained"
              disableElevation
              className="PrimaryCTAExp"
              sx={{
                marginLeft: "auto",
                display: "block",
                marginTop: "15px",
                position: "relative",
              }}
              disabled={state.isUpdating}
              onClick={handleUpdateProfessionalProfile}
            >
              {state.isUpdating ? (
                <div className="d-flex align-items-center gap-2">
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                    }}
                  />
                  <span>Updating</span>
                </div>
              ) : (
                "Update Professional Details"
              )}
            </Button>
          )}
        </div>
      </div>

      <Snackbar
        open={state.openSnackbar}
        autoHideDuration={6000}
        onClose={() => handleStateChange("openSnackbar", false)}
      >
        <Alert
          onClose={() => handleStateChange("openSnackbar", false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {state.snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProfessionalProfile;
