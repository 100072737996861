import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  IconButton,
  Switch,
  Menu,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, ContentCopy, Edit } from "@mui/icons-material";
import { useAuth } from "../../../auth";
import TimezoneAndDateOverride from "./TimezoneAndDateOverride";
import { useSnackbar } from "notistack";
const Scheduler = () => {
  const [availability, setAvailability] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [workHours, setWorkHours] = useState({
    monday: [{ start: "09:00", end: "17:00" }],
    tuesday: [{ start: "09:00", end: "17:00" }],
    wednesday: [{ start: "09:00", end: "17:00" }],
    thursday: [{ start: "09:00", end: "17:00" }],
    friday: [{ start: "09:00", end: "17:00" }],
    saturday: [{ start: "09:00", end: "17:00" }],
    sunday: [{ start: "09:00", end: "17:00" }],
  });

  const [copyTargetDays, setCopyTargetDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [sourceDay, setSourceDay] = useState("");
  const [edit, setEdit] = useState(false);
  const [tempData, setTempData] = useState(null);
  const { currentUser } = useAuth();
  const [holidayModes, setHolidayModes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const toggleDay = (day) => {
    setAvailability((prev) => ({ ...prev, [day]: !prev[day] }));
    setWorkHours((prev) => ({
      ...prev,
      [day]:
        workHours[day].length > 0
          ? workHours[day]
          : [{ start: "09:00", end: "17:00" }],
    }));
  };

  const openMenu = (event, day) => {
    setSourceDay(day);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setCopyTargetDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
  };

  const copySlots = () => {
    const slotsToCopy = workHours[sourceDay];
    const newWorkHours = { ...workHours };

    Object.keys(copyTargetDays).forEach((day) => {
      if (copyTargetDays[day] && day !== sourceDay) {
        newWorkHours[day] = [...slotsToCopy];
        setAvailability((prev) => ({ ...prev, [day]: true }));
      }
    });

    setWorkHours(newWorkHours);
    closeMenu();
  };

  const handleSelectedDayChange = (day) => {
    setCopyTargetDays((prev) => ({ ...prev, [day]: !prev[day] }));
  };

  const convertTo12Hour = (time24) => {
    let [hour, minute] = time24.split(":").map(Number);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const timeOptions = [];
  for (let i = 0; i < 24 * 4; i++) {
    const hours = Math.floor(i / 4);
    const minutes = (i % 4) * 15;
    const formattedTime24 = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    const formattedTime12 = convertTo12Hour(formattedTime24);
    timeOptions.push({ value: formattedTime24, label: formattedTime12 });
  }
  timeOptions.push({ value: "23:59", label: "11:59 PM" });

  const addNextAvailability = (day) => {
    const lastSlot = workHours[day][workHours[day].length - 1];
    const nextStart = lastSlot.end;
    const nextEnd = calculateNextEndTime(nextStart);
    setWorkHours((prev) => ({
      ...prev,
      [day]: [...prev[day], { start: nextStart, end: nextEnd }],
    }));
  };

  const calculateNextEndTime = (start) => {
    const [hour, minute] = start.split(":").map(Number);
    const newHour = (hour + 1) % 24;
    const formattedHour = newHour.toString().padStart(2, "0");
    return `${formattedHour}:${minute.toString().padStart(2, "0")}`;
  };

  const handleTimeChange = (day, index, type, value) => {
    const updatedHours = [...workHours[day]];

    if (type === "start") {
      const currentEndTime = updatedHours[index].end;
      const newStartTime = value;

      if (newStartTime < currentEndTime) {
        updatedHours[index].start = newStartTime;
      } else {
        updatedHours[index].start = newStartTime;
        updatedHours[index].end = calculateNextEndTime(newStartTime);
      }
    } else {
      updatedHours[index].end = value;
    }

    setWorkHours((prev) => ({
      ...prev,
      [day]: updatedHours,
    }));
  };

  const filterEndTimeOptions = (startTime) => {
    return timeOptions.filter((option) => option.value > startTime);
  };

  const deleteAvailability = (day, index) => {
    setWorkHours((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
  };
  const convertTo24Hour = (time12) => {
    const [time, modifier] = time12.split(" ");
    let [hour, minute] = time.split(":").map(Number);

    if (modifier === "PM" && hour < 12) {
      hour += 12;
    }
    if (modifier === "AM" && hour === 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const setWorkHoursFromData = (data) => {
    const dayMapping = {
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
      7: "sunday",
    };

    const newWorkHours = {};
    const newAvailability = {};

    // Loop through each day in the provided data
    Object.keys(data).forEach((dayNumber) => {
      const dayName = dayMapping[dayNumber]; // Map numeric key to day name

      // Convert each time slot from 12-hour to 24-hour format
      const convertedSlots = data[dayNumber].map((slot) => ({
        start: convertTo24Hour(slot?.start),
        end: convertTo24Hour(slot?.end),
      }));

      // Update the corresponding day in newWorkHours
      newWorkHours[dayName] = convertedSlots;

      // Set availability to true if the length of time slots is greater than 0
      newAvailability[dayName] = convertedSlots.length > 0;
    });

    // Set the availability and workHours state with the new data
    setAvailability(newAvailability);

    setWorkHours(newWorkHours);
  };

  const FormatData = (work) => {
    const formattedData = {
      1: [], // Monday
      2: [], // Tuesday
      3: [], // Wednesday
      4: [], // Thursday
      5: [], // Friday
      6: [], // Saturday
      7: [], // Sunday
    };

    Object.keys(work).forEach((day, index) => {
      const dayIndex = (index + 1) % 7 || 7; // Adjust index for mapping (Monday=1, Sunday=7)
      const slots = work[day]; // Get the slots for the current day

      formattedData[dayIndex] = availability[day]
        ? slots.map((slot) => ({
            start: convertTo12Hour(slot.start),
            end: convertTo12Hour(slot.end),
          }))
        : [];
    });

    return formattedData;
  };

  const submitData = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_US_BASE_URL}/new-uwc/uwc_expert/my-schedule/update`,
        {
          method: "POST",
          headers: {
            "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            professional_id: currentUser?.UserId,
            schedule: FormatData(workHours),
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data?.code === 200) {
        setEdit(false);
        enqueueSnackbar("Schedule updated successfully", {
          variant: "success",
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch schedule data
        const scheduleResponse = await fetch(
          `${process.env.REACT_APP_US_BASE_URL}/new-uwc/uwc_expert/my-schedule/get`,
          {
            method: "POST",
            headers: {
              "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              professional_id: currentUser?.UserId,
            }),
          }
        );

        // Fetch holiday modes
        const holidayResponse = await fetch(
          `${process.env.REACT_APP_US_BASE_URL}/new-uwc/expert/dashboard/get-holiday-modes`,
          {
            method: "POST",
            headers: {
              "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
              "Content-Type": "application/json",
              Authorization: `Bearer ${currentUser?.token}`, // Make sure you have access to the token
            },
            body: JSON.stringify({
              expert_id: currentUser?.UserId,
            }),
          }
        );

        if (!scheduleResponse.ok || !holidayResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const scheduleData = await scheduleResponse.json();
        const holidayData = await holidayResponse.json();

        if (scheduleData?.code === 200) {
          setWorkHoursFromData(scheduleData?.data?.schedule);
        }

        if (holidayData?.code === 200) {
          setHolidayModes(holidayData.data?.holidays || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser?.UserId, currentUser?.token]);

  const LoadingSkeleton = () => (
    <div className="px-4">
      <Grid container justifyContent="flex-end" className="mb-4">
        <Skeleton variant="rounded" width={200} height={50} />
      </Grid>
      {[...Array(7)].map((_, index) => (
        <Grid container spacing={2} key={index} className="mb-3">
          <Grid item xs={2}>
            <Skeleton variant="text" width={100} height={40} />
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={2}>
              <Grid item>
                <Skeleton variant="rounded" width={120} height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width={20} height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="rounded" width={120} height={40} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );

  return (
    <div className=" border rounded-4" style={{ padding: "20px" }}>
      <div className="my-3 ScheduleDashboard">
        <div className="ScheduleDashboard--Header d-flex">
          <h2>
            My Schedule{" "}
            {!edit && !loading && (
              <IconButton
                onClick={() => {
                  setEdit(true);
                  setTempData(FormatData(workHours));
                }}
              >
                <Edit />
              </IconButton>
            )}
          </h2>
        </div>
      </div>

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <Grid
          container
          className="px-4"
          display={"flex"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Grid>
            {Object.keys(availability).map((day) => (
              <Grid item xs={12} key={day}>
                <Grid container spacing={1}>
                  {edit && (
                    <Grid item className="mt-2">
                      <FormControlLabel
                        control={
                          <Switch
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "var(--primary-base-color)",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "var(--primary-base-color)",
                                },
                            }}
                            checked={availability[day]}
                            onChange={() => toggleDay(day)}
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </Grid>
                  )}{" "}
                  <Grid minWidth={150} item className="mt-3 ">
                    <Typography
                      sx={{
                        textDecorationLine: !availability[day]
                          ? "line-through"
                          : "none",
                      }}
                      variant="body1"
                    >
                      {day.charAt(0).toUpperCase() + day.slice(1)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {availability[day] ? (
                      <div style={{ marginTop: "10px" }}>
                        {workHours[day].map((slot, index) => (
                          <Grid
                            container
                            marginBottom={1}
                            spacing={1}
                            key={index}
                            alignItems="center"
                          >
                            <Grid item>
                              {edit ? (
                                <FormControl size="small">
                                  <InputLabel></InputLabel>
                                  <Select
                                    sx={{
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            "var(--primary-base-color)",
                                        },
                                    }}
                                    label=""
                                    value={slot?.start}
                                    onChange={(e) =>
                                      handleTimeChange(
                                        day,
                                        index,
                                        "start",
                                        e.target.value
                                      )
                                    }
                                  >
                                    {timeOptions.map((time) => (
                                      <MenuItem
                                        key={time?.value}
                                        value={time?.value}
                                      >
                                        {time.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <Typography>
                                  {convertTo12Hour(slot?.start)}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item>
                              <Typography>-</Typography>
                            </Grid>
                            <Grid item>
                              {edit ? (
                                <FormControl size="small">
                                  <InputLabel></InputLabel>
                                  <Select
                                    sx={{
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            "var(--primary-base-color)",
                                        },
                                    }}
                                    label=""
                                    value={slot?.end}
                                    onChange={(e) =>
                                      handleTimeChange(
                                        day,
                                        index,
                                        "end",
                                        e.target.value
                                      )
                                    }
                                  >
                                    {filterEndTimeOptions(slot?.start).map(
                                      (time) => (
                                        <MenuItem
                                          key={time?.value}
                                          value={time?.value}
                                        >
                                          {time.label}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <Typography>
                                  {convertTo12Hour(slot?.end)}
                                </Typography>
                              )}
                            </Grid>
                            {edit && index !== 0 && (
                              <Grid item>
                                <IconButton
                                  onClick={() => deleteAvailability(day, index)}
                                  sx={{ color: "var(--primary-base-color)" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            )}

                            {edit && index === 0 && (
                              <Grid item>
                                <IconButton
                                  onClick={() => addNextAvailability(day)}
                                  sx={{ color: "var(--primary-base-color)" }}
                                >
                                  <Add />
                                </IconButton>
                              </Grid>
                            )}
                            {/* {edit && index === 0 && (
                              <Grid item>
                                <IconButton
                                  onClick={(e) => openMenu(e, day)}
                                  sx={{ color: "var(--primary-base-color)" }}
                                >
                                  <ContentCopy />
                                </IconButton>
                              </Grid>
                            )} */}
                          </Grid>
                        ))}
                      </div>
                    ) : (
                      <Typography className="mt-3">Unavailable</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}{" "}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <Typography sx={{ padding: "8px 16px" }}>
                Copy slots from{" "}
                {sourceDay.charAt(0).toUpperCase() + sourceDay.slice(1)} to:
              </Typography>
              {Object.keys(availability).map((day) => (
                <MenuItem key={day}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={copyTargetDays[day]}
                        onChange={() => handleSelectedDayChange(day)}
                        disabled={day === sourceDay} // Disable the source day
                      />
                    }
                    label={day.charAt(0).toUpperCase() + day.slice(1)}
                  />
                </MenuItem>
              ))}
              <MenuItem>
                <Button
                  onClick={copySlots}
                  sx={{ color: "var(--primary-base-color)" }}
                >
                  Copy
                </Button>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid>
            <TimezoneAndDateOverride holidayModes={holidayModes} />
          </Grid>
        </Grid>
      )}

      {edit && (
        <div className="d-flex justify-content-end gap-3">
          {" "}
          <Button
            variant="text"
            sx={{
              borderRadius: "20px", // Rounded but not too much
              bgcolor: "#f5f5f5",
              color: "#414141",
              fontSize: "14px", // 14px as in the default theme
              textTransform: "capitalize",
              "&:hover": {
                background: "#f5f5f5",
              },
            }}
            className=" rounded-3 px-3"
            onClick={() => {
              setWorkHoursFromData(tempData);
              setEdit(false);
              setTempData(null);
            }}
          >
            Cancel
          </Button>
          <Button
            className="PrimaryCTAExp"
            onClick={submitData}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Scheduler;
