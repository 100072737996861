import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce, throttle } from "lodash";
import { useAuth } from "../../../auth";

const SelectClientModal = ({ open, handleClose, setSelectedClient }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { currentUser } = useAuth();
  const [searchClientbyName, setSerachClientbyName] = useState("");
  const [clientList, setClientList] = useState([]);

  // Debounced search function (3000ms delay)
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      fetchClients(searchTerm, 1); // Reset to page 1 when searching
    }, 3000),
    []
  );

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  // Throttled pagination function (1 request per second)
  const throttledLoadMore = useCallback(
    throttle(() => {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchClients(searchClientbyName, nextPage);
        return nextPage;
      });
    }, 1000),
    [searchClientbyName]
  );

  // Handle scroll for infinite loading
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loading) {
      throttledLoadMore();
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSerachClientbyName(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    fetchClients(searchClientbyName, 1);
  }, [searchClientbyName]);

  const fetchClients = async (searchClientbyName, page) => {
    const payload = {
      expert_id: currentUser?.UserId,
      client_name: searchClientbyName,
      page: page,
    };

    try {
      setLoading(true);
      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/clients`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      const { clients } = postResponse.data;

      setClientList(
        (prevClients) => (page === 1 ? clients : [...prevClients, ...clients]) // Reset when page is 1
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectClient = (item) => {
    setSelectedClient(item);
    handleClose();
    setSerachClientbyName("");
    debouncedSearch("");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Client</DialogTitle>
      <DialogContent>
        <FormControl fullWidth className="mt-4 ClientSelect--Form">
          <InputLabel htmlFor="search-client">Search Client</InputLabel>
          <Input
            id="search-client"
            placeholder="Search Client"
            value={searchClientbyName}
            onChange={handleSearchChange}
          />
        </FormControl>

        <List
          sx={{ maxHeight: 400, overflow: "auto", mt: 2 }}
          onScroll={handleScroll}
        >
          {clientList?.map((item) => (
            <ListItem
              key={item.ID}
              button
              onClick={() => handleSelectClient(item)}
              sx={{ cursor: "pointer" }}
            >
              <div className="d-flex gap-2 ClientSelect--MenuItem w-100">
                <img
                  src={item.ProfileImage}
                  width={45}
                  height={45}
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt={item.Name}
                />
                <div>
                  <h6>{item.Name}</h6>
                  <p>{item.Country}</p>
                </div>
              </div>
            </ListItem>
          ))}
          {loading && (
            <ListItem sx={{ justifyContent: "center" }}>
              <CircularProgress size={24} />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectClientModal;
